import React from 'react'

const Footer = () => {
  return (
    <section className="section footer-card">
      <div className="columns donation-card">
        <div className="column is-three-fifths donation-card__content">
          <div className="donation-card__title">Vous voulez papoter ?</div>
          <div className="donation-card__text">
            - Par email > <a href="mailto:hello@saintmartinparis.com" target="_blank">hello@saintmartinparis.com</a>.<br/>
            - Sur instagram > <a href="http://instagram.com/saintmartinparis" target="_blank">@saintmartinparis</a><br/>
            <br/>
            À très vite :)
          </div>
        </div>
        <div className="column is-two-fifths donation-card__logo">
          <img src="../Kindness.png" />
        </div>
      </div>
    </section>
  )
}

export default Footer
