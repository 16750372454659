import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'
import ShopBanner from '../components/shopBanner'
import Footer from '../components/footer'


const SuccessPage = ({ location }) => {
  let query = new URLSearchParams(location.search);
  const [transaction, setTransaction] = React.useState(null);

  React.useEffect(() => {
    async function fetchData() {
      const res = await fetch(`https://tomhouse.fr/stripe/getSession.php?session_id=${query.get('session_id')}`);
      const json = await res.json();
      setTransaction(json);
      if(json['email'] === true) {
        console.log('Purchase complete');
        if (typeof window !== "undefined") {
          if (window.fbq != null) {
            window.fbq('track', 'Purchase',   {
              value: json ? (json.amount_total || 0) : 0,
              currency: "EUR",
              content_type: 'product', // required property
              content_ids: 'txpg522i2z' // required property
            });
          }
        }
      }
    }
    fetchData();
  },[]);

 return (
   <Layout>
     <SEO title="St-Martin - Le Tshirt" />
     <section className="section success-content">
      <img src="../yeah.png" alt="yeah" className="img-banner" />
       <div className="block">
         <strong>Votre commande est confirmée, un peu de patience et Jacques sera à vous.</strong><br/><br/>
         {!transaction ?
           <progress className="progress is-small is-primary" max="100">15%</progress> :
         <>

           Un email va vous être envoyé à l'adresse : {transaction?.customer_details?.email}<br/><br/>
           Récapitulatif de votre commande #{ transaction?.order }<br/>
           {transaction?.items.map((item, index) => (
             <span key={index}><span>{item.quantity} x {item.description}</span><br/></span>
           ))}
           <strong>Total TTC : {(transaction?.amount_total / 100).toFixed(2)}€</strong><br/><br/></>
         }

         Merci et à très vite<br/><br/>
         PS : si voulez récidiver, vous pouvez re-commander juste en dessous. On dit ça, on dit rien.
       </div>
     </section>
     <ShopBanner />
     <Footer/>
   </Layout>
 )
}

export default SuccessPage;
